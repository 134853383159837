const md5 = require('md5');
//统计
(function () {
  const cnzzMap = [
    {
      "domain": "freedom.jgwcjm.com",
      "id": "cnzz_stat_icon_1279029118",
      "src": "https://hm.baidu.com/hm.js?6a2a0eee8ff26686c829ef2c834e71b2"
    },
    {
      "domain": "antifakeh5.jgwcjm.com",
      "id": "cnzz_stat_icon_1279029135",
      "src": "https://hm.baidu.com/hm.js?01f104ffdb9be7275a7235feacdb7c1b"
    },
    {
      "domain": "traceh5.jgwcjm.com",
      "id": "cnzz_stat_icon_1279029140",
      "src": "https://hm.baidu.com/hm.js?fee0c396ad43ca0f797bc0cf1ed5319c"
    },
    {
      "domain": "h5tools.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030054",
      "src": "https://hm.baidu.com/hm.js?c896f1c051db53322145c43181fdd50b"
    },
    {
      "domain": "marketh5.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030198",
      "src": "https://hm.baidu.com/hm.js?871884cdcac2f9466df7197798352fb7"
    },
    {
      "domain": "shoph5.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030246",
      "src": "https://hm.baidu.com/hm.js?22d9d7c52090e85c11ef651b07a4106a"
    },
    {
      "domain": "nurtureh5.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030274",
      "src": "https://hm.baidu.com/hm.js?8b989b1c4bdaa763ec750e541319b614"
    },
    {
      "domain": "aio.h5.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030284",
      "src": "https://hm.baidu.com/hm.js?4c18f425c3b4b9ee1dfd63b0b58c4af1"
    },
    {
      "domain": "designw.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030310",
      "src": "https://hm.baidu.com/hm.js?cad914250f155c0da45c0baa565f1373"
    },
    {
      "domain": "antifake.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030347",
      "src": "https://hm.baidu.com/hm.js?0a24925155e5d6691c1f9cef047493a1"
    },
    {
      "domain": "poverty.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030355",
      "src": "https://hm.baidu.com/hm.js?da0e3dc682e95485a68d107f62057035"
    },
    {
      "domain": "xc.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030711",
      "src": "https://hm.baidu.com/hm.js?748ed09b8a437f4873bd0fceb202eb64"
    },
    {
      "domain": "b2b.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030737",
      "src": "https://hm.baidu.com/hm.js?05648a9a7cfb69a2f460d8c85c43c80f"
    },
    {
      "domain": "zly.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030746",
      "src": "https://hm.baidu.com/hm.js?afd552b90a04e33118f063439eeeff9d"
    },
    {
      "domain": "org.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030768",
      "src": "https://hm.baidu.com/hm.js?aec0c1f2c70705aaad2236f7616d6557"
    },
    {
      "domain": "platform.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030786",
      "src": "https://hm.baidu.com/hm.js?2847fd6eee324396a6311e6c6ebe9a4b"
    },
    {
      "domain": "login.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030799",
      "src": "https://hm.baidu.com/hm.js?565e7031238c40d08964d97ada644125"
    },
    {
      "domain": "jointchain.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030316",
      "src": "https://hm.baidu.com/hm.js?48e755f79b2d816c04a93f36c0e04bc6"
    },
    {
      "domain": "iot.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030386",
      "src": "https://hm.baidu.com/hm.js?96de16b49d07e124cd7e9e0109be0291"
    },
    {
      "domain": "commonh5.jgwcjm.com",
      "id": "cnzz_stat_icon_1279030386",
      "src": "https://hm.baidu.com/hm.js?7db73aee5479dffce3c62035d0057900"
    },
    {
      "domain": "jointchain.net",
      "id": "cnzz_stat_icon_1279030386",
      "src": "https://hm.baidu.com/hm.js?dc842e448f21698985bc50d32640c1a4"
    },
    {
      "domain": "jgwcjm.com",
      "id": "cnzz_stat_icon_1279030806",
      "src": "https://hm.baidu.com/hm.js?72faa13660e64612470d550ebb172a97"
    }
  ];
  try {
    const matchItem = cnzzMap.find(item => {
      const hostname = window.location.hostname.toLocaleLowerCase();
      const domain = item.domain.toLocaleLowerCase();
      return hostname.indexOf(domain) >= 0;
    });
    if (matchItem) {
      document.write(`<div class="cnzz-wrap" style="opacity: 0"><span id="${matchItem.id}"></span><script src="${matchItem.src}"  type="text/javascript"></script></div>`);
      document.write(`<style>.cnzz-wrap{position: absolute;left:0;top:0;width:1px;height:1px;overflow:hidden;opacity:0}</style>`);
    }
  } catch (err) {
    console.error(err);
  }
})();

//copy right
(function () {
  if (!window.jgwcjmPackage) {
    window.jgwcjmPackage = require('./modules/jgwcjmPackage');
  }
})();
//
require('./modules/icon').init();
// 随机数
function fullClose(n, m) {
  //[n,m]
  var result = Math.random() * (m + 1 - n) + n;
  while (result > m) {
    result = Math.random() * (m + 1 - n) + n;
  }
  return Math.round(result);
}

window.setEncryptionParameter = function (opt){
  if (!opt.headers) {  
    opt.headers = {};
  }
  let len = fullClose(1,30)
  let i = fullClose(1,3)
  let a = ['c','j','m']
  let b = {
    c:'f31eac62dfa1435b8ff733cc92685a37',
    j:'64467f7242bd473e952f3490a879ee2c',
    m:'bf48ed6feefe11e994815254006b94c1',
    a:'143cbd3737dcb447971cd785107e8a64',
    z:'b78ca2cab9525fb81182f8c7101af17c',
    y:'180113cca53cc9a8dd8a953853b5d4c7'
  }
  let t = Math.round(new Date())
  let md5t = md5(b[a[i-1]]+t)
  md5t = md5t.substr(0,len)+i+md5t.substr(len)
  opt.headers['m-ts'] = t;
  opt.headers['m-type'] = "market";
  opt.headers['c'] = b.c;
  opt.headers['j'] = b.j;
  opt.headers['m'] = b.m;
  opt.headers['e'] = md5t;
  opt.headers['d'] = len;
  opt.headers['p'] = md5(md5t+t);
  opt.headers['t'] = md5(t+len);
  opt.headers['a'] = b.a;
  opt.headers['z'] = b.z;
  opt.headers['y'] = b.y;
  opt.headers['l'] = len+5;
  opt.headers['k'] = len+2;
  opt.headers['i'] = len+i;
  return opt
}