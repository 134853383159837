function onReady(callback) {
  if (document.readyState === 'complete') {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback, false);
  }
}

module.exports = {
  init() {
    onReady(function () {
      const iconLinks = Array.prototype.slice.call(document.querySelectorAll('link[rel="icon"]'));
      if (iconLinks.length === 0) {
        const link = document.createElement('link');
        link.setAttribute('rel', 'icon');
        link.setAttribute('href', 'https://freedom.jgwcjm.com/framework/images/title-icon.png');
        document.head.appendChild(link);
      }
    });
  }
}