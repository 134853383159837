function createIcpNumber(domain = '', number = '', publicCode = '', link = 'https://beian.miit.gov.cn') {
  return { domain, number, publicCode, link };
}

const jgwcjmPackage = {};


//
jgwcjmPackage.copyRight = {
  name: '超级码科技股份',
  validity: { start: '2005', end: '2025' },
  icpNumbers: [
    createIcpNumber('app315.net', '浙ICP备09106406号', '33010602001041'),
    createIcpNumber('a-b.com.cn', '浙ICP备09106406号-8', '33010602001120'),
    createIcpNumber('df315.net', '浙ICP备09106406号-4'),
    createIcpNumber('wl315.net', '浙ICP备09106406号-3', '33010602000994'),
    createIcpNumber('aipackplus.com', '浙ICP备09106406号-29'),
    createIcpNumber('51cjm.cn', '浙ICP备09106406号-27'),
    createIcpNumber('51cjm.net', '浙ICP备09106406号-26'),
    createIcpNumber('jgwcjm.com', '浙ICP备09106406号-23'),
    createIcpNumber('chaojima.co', '浙ICP备09106406号-22'),
    createIcpNumber('jxndxsy.com', '浙ICP备09106406号-21', '33010602009017'),
    createIcpNumber('dxxncpjg.com', '浙ICP备09106406号-20', '33010602009018'),
    createIcpNumber('ncp315.org', '浙ICP备09106406号-19', '33010602008016'),
    createIcpNumber('ncphgz.org', '浙ICP备09106406号-18', '33010602008015'),
    createIcpNumber('ptqc315.org', '浙ICP备09106406号-17', '33010602007821'),
    createIcpNumber('cjw.so', '浙ICP备09106406号-16', '33010602001125'),
    createIcpNumber('kf315.net', '浙ICP备09106406号-15'),
    createIcpNumber('s315.net', '浙ICP备09106406号-14', '33010602001124'),
    createIcpNumber('cjm.so', '浙ICP备09106406号', '33010602001163'),
    createIcpNumber('df315.cn', '浙ICP备09106406号-10'),
    createIcpNumber('gov315.com', '浙ICP备09106406号-1'),
    createIcpNumber('51chaojima.com', '浙ICP备09106406号-24'),
    createIcpNumber('51cjm.co', '浙ICP备09106406号-25'),
    createIcpNumber('chaojima.com', '浙ICP备09106406号-30'),
    createIcpNumber('jointchain.net', '浙ICP备09106406号-28'),
    createIcpNumber('chaojimayun.com', '浙ICP备09106406号-31'),
    createIcpNumber('cjmcloud.com', '浙ICP备09106406号-32'),
    createIcpNumber('aibox.jgwcjm.com', '浙ICP备09106406号'),
  ],
  getIcpNumber: () => {
    let domain = window.location.host || window.document.domain;
    //获取一级域名
    domain = domain.split('.').reverse().filter((item, index) => index < 2).reverse().join('.')
    let result = jgwcjmPackage.copyRight.icpNumbers.find(item => {
      return item.domain.toLocaleLowerCase() === domain.toLocaleLowerCase();
    });
    if (!result) {
      result = { domain: '', number: '', publicCode: '', link: '' };
    }
    return result;
  },
  getPublicCodeLink: () => {
    const ipcNumber = jgwcjmPackage.copyRight.getIcpNumber();
    const publicCode = ipcNumber.publicCode;
    if (publicCode) {
      return `http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${publicCode}`;
    }
    return '';
  },
  getContent: () => {
    // const { start, end } = jgwcjmPackage.copyRight.validity;
    // const { name } = jgwcjmPackage.copyRight;
    // return `Copyright ${start}-${end} 技术架构：${name} 版权所有Inc. All Reserved.`;
    return 'Copyright 2005 Chaojima. All Rights Reserved ';
  },
}
//
module.exports = jgwcjmPackage;
